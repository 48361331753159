@import "../../components/variables.scss";

.products-history-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  .product-history-toolbar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    height: 95px;
    margin-bottom: 20px;
    .history-tiles-list {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      height: 100%;
      width: 100%;
      .product-history-time-tile {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        height: 95px;
        width: 240px;
        padding: 5px 15px;
        border-radius: 7px;
        border: 3px solid #ccc8c8;
        background: #ffff;
        cursor: pointer;
        .tile-first {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          @include h3-style;
          .first-title {
            font-weight: 800;
            &.active {
              color: #6366f1;
            }
          }
          input {
            display: none;
          }
          button {
            height: 24px;
            width: 24px;
            border-radius: 7px;
            cursor: pointer;
          }
        }
        .tile-amount {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          line-height: 30px;
          @include h4-style;
        }
        .tile-last {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: center;
          @include table-style;
          .last-legend {
            font-weight: 700;
            padding-right: 10px;
            color: #5d6b83;
          }
          .last-qty {
            font-weight: 800;
            font-size: 15px;
          }
        }
      }
      .product-history-time-tile.active {
        border: 3px solid #5887ff;
      }
    }
  }
  .product-history-list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    height: calc(100% - 130px);
    margin-bottom: 15px;
    .recepcionista-field {
      width: calc(calc(100% - 80px) * 0.35) !important;
      min-width: calc(calc(100% - 80px) * 0.35) !important;
      max-width: calc(calc(100% - 80px) * 0.35) !important;
    }
    .monto-field {
      width: calc(calc(100% - 80px) * 0.3) !important;
      min-width: calc(calc(100% - 80px) * 0.3) !important;
      max-width: calc(calc(100% - 80px) * 0.3) !important;
    }
    .venta-field {
      width: calc(calc(100% - 80px) * 0.35) !important;
      min-width: calc(calc(100% - 80px) * 0.35) !important;
      max-width: calc(calc(100% - 80px) * 0.35) !important;
    }
    .acciones-field {
      width: 80px !important;
      min-width: 80px !important;
      max-width: 80px !important;
    }
  }
}

.modal-products-detail,
.modal-products-delete {
  .modal-content {
    border-radius: 1rem;
    .modal-header {
      border-bottom: unset;
      padding: 0.75rem 0.75rem 0 0.75rem;
      .title {
        color: $TopHeaderLabelColor;
        @include h2-style;
      }
      svg {
        width: 30px;
        height: 30px;
      }
    }
    .modal-body {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
      .p-datatable-wrapper {
        height: 100% !important;
      }
      .nombre-field {
        width: calc(calc(100% - 260px)) !important;
        min-width: calc(calc(100% - 260px)) !important;
        max-width: calc(calc(100% - 260px)) !important;
      }
      .cantidad-field {
        width: 80px !important;
        min-width: 80px !important;
        max-width: 80px !important;
      }
      .unitario-field {
        width: 90px !important;
        min-width: 90px !important;
        max-width: 90px !important;
      }
      .subtotal-field {
        width: 90px !important;
        min-width: 90px !important;
        max-width: 90px !important;
      }
    }
    .modal-footer {
      border-top: unset;
      padding: 0 0.75rem 0.75rem 0.75rem;
    }
  }
}
.p-column-title {
  color: #343a40 !important;
}
.subtotal-data {
  color: #256029 !important;
}
