$BackgroundColor: #172b4d;
$WhiteColor: #ffff;
$GrayColor: #a2aab8;
$LightGrayColor: #d5d5d5;
$BodyBackgroundColor: #e8ecf3;
$ElementColor: #98a1b3;
$ElementBackgroundColor: #eff0f6;

$IconTopRightHeightWidth: 25px;
$IconMenuHeightWidth: 35px;
$IconTopHeightWidth: 30px;
$IconSearchBoxHeightWidth: 26px;

//Left-side-menu
$MenuTitleBorderColor: #2e405f;
$MenuOptionHeight: 60px;
$MenuOptionLabelColor: #a2aab8;
$MenuOptionLabelActiveColor: #ffff;
$SubListBackgroundColor: #273e65;
$SubListBorderCorlor: #455c84;

//Right-side-filter
$SidebarBackground: #172b4d;
$SidebarColor: #ffff;
$SidebarTabBackground: #273e65;
$SideElementBottomBorder: #748095;
$SideSubElementBackground: #3d618f8c;

//Top-header
$TopHeaderLabelColor: #172b4d;

//Filter-option
$FilterLabelColor: #757575;
$FilterBorderColor: #eff0f6;
$FilterActiveLabelColor: #ffff;
$FilterActiveBackground: #172b4d;

//Search-box
$SearchLabelColor: #757575;

//Action-button
$ButtonAddColor: #00a35a;
$ButtonSellColor: #00a35a;
$ButtonPdfColor: #d89b17;
$ButtonSearchColor: #3c63bc;
$ButtonDeleteColor: #ff4a57;
$ButtonCancelColor: #757575;
$ButtonReduceColor: #ff4a57;
$ButtonCloseColor: #757575;
$ButtonLabelColor: #ffff;
$IconActionHeightWidth: 20px;

//Selector-option
$SelectorLabelColor: #757575;
$SelectorBackgroundColor: #ffff;
$SelectorOptionBackgroundColor: #ededed;
$SelectorBorderColor: #eff0f6;
$SelectorActiveLabelColor: #5887ff;

//Table
$TableBackgroundColor: #ffff;
$HeadBorderColor: #dadbe4;
$BodyBorderColor: #eff0f6;
$HeadLabelColor: #172b4d;
$BodyLabelColor: #5d6b83;
$IconSortHeightWidth: 18px;

$StockBackgroundColor: #f4f7fc;
$StockLabelColor: #9a9a9a;

$DetailBorderColor: #afafaf;

$BebidaColor: #ffcb2f;
$SnackColor: #ff1eb0;
$GalletaColor: #ff4a57;
$ChocolateColor: #5887ff;
$CuidadoColor: #00a35a;
$CarameloColor: #ff9221;
$CigarroColor: #8b95a6;

$RecepcionistaBackgroundColor: #f2faf6;
$RecepcionistaColor: #00a35a;
$LimpiezaBackgroundColor: #fff2f1;
$LimpiezaColor: #ff796b;
$AdminBackgroundColor: #f2f6ff;
$AdminColor: #5887ff;

$DateCreateBackgroundColor: #00a35a;
$DateModifyBackgroundColor: #ff9221;

$BanReasonLabelColor: #ff796b;

$DateInRentBackgroundColor: #00a35a;
$DateOutBackgroundColor: #ff9221;
$DateOutRealBackgroundColor: #5887ff;

@mixin light-style {
  font-size: 14px;
  font-weight: 500;
  font-family: "Lato", sans-serif;
}

@mixin normal-style {
  font-size: 14px;
  font-weight: 700;
  font-family: "Lato", sans-serif;
}

@mixin h1-style {
  font-size: 13px;
  font-weight: 600;
  font-family: "Lato", sans-serif;
}

@mixin h2-style {
  font-size: 19px;
  font-weight: 800;
  font-family: "Lato", sans-serif;
}

@mixin h3-style {
  font-size: 16px;
  font-weight: 600;
  font-family: "Lato", sans-serif;
}

@mixin h4-style {
  font-size: 28px;
  font-weight: 800;
  font-family: "Lato", sans-serif;
}

@mixin h5-style {
  font-size: 17px;
  font-weight: 800;
  font-family: "Lato", sans-serif;
}

@mixin h6-style {
  font-size: 35px;
  font-weight: 800;
  font-family: "Lato", sans-serif;
}

@mixin h7-style {
  font-size: 21px;
  font-weight: 800;
  font-family: "Lato", sans-serif;
}

@mixin h8-style {
  font-size: 14px;
  font-weight: 550;
  font-family: "Lato", sans-serif;
}

@mixin h9-style {
  font-size: 14px;
  font-weight: 550;
  font-family: "Lato", sans-serif;
}

@mixin h10-style {
  font-size: 15px;
  font-weight: 500;
  font-family: "Lato", sans-serif;
}

@mixin h11-style {
  font-size: 15px;
  font-weight: 600;
  font-family: "Lato", sans-serif;
}

@mixin table-style {
  font-size: 14px;
  font-weight: 600;
  font-family: "Lato", sans-serif;
}

@mixin scroll-y {
  overflow-y: auto;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 5px;
    height: 0px;
  }

  &::-webkit-scrollbar-track {
    width: 5px;
    height: 0px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #bebbbb;
  }
  &::-webkit-scrollbar-track {
    background-color: #efefef;
  }
}

@mixin scroll-x {
  overflow-x: auto;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 0px;
    height: 5px;
  }

  &::-webkit-scrollbar-track {
    width: 0px;
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #bebbbb;
  }
  &::-webkit-scrollbar-track {
    background-color: #efefef;
  }
}
