@import "../../components/variables.scss";

.rooms-rent-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  height: 100%;
  width: 100%;
  position: relative;
  .room-rent-list {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: flex-end;
    flex-wrap: wrap;
    height: calc(100% + 20px);
    width: calc(100% - 420px);
    margin: -20px -30px 0px 0px;
    transition: width 0.5s;
    @include scroll-y;
    &.active {
      width: calc(100% - 55px);
      transition: width 0.5s;
    }
    .room-rent-room-tile {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      position: relative;
      width: 185px;
      height: 175px;
      border-radius: 10px;
      overflow: hidden;
      margin: 0 20px 20px 0;
      &.status-libre {
        border: 4px solid $CuidadoColor;
      }
      &.status-mantenimiento {
        border: 4px solid $CigarroColor;
      }
      &.status-ocupado {
        border: 4px solid $GalletaColor;
      }
      &:first-child {
        margin-top: 20px;
      }
      .room-tile-insignia {
        position: absolute;
        top: 0;
        right: 0;
        width: 0;
        height: 0;
        border-left: 60px solid transparent;
        &.status-libre {
          border-top: 60px solid $CuidadoColor;
        }
        &.status-mantenimiento {
          border-top: 60px solid $CigarroColor;
        }
        &.status-ocupado {
          border-top: 60px solid $GalletaColor;
        }
      }
      .room-icon {
        position: absolute;
        top: 3px;
        right: 3px;
        width: 25px;
        height: 25px;
        color: #ffff;
      }
      .room-tile-number {
        @include h6-style;
      }
      .room-tile-type {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        .type-main {
          @include light-style;
        }
        .type-content {
          margin-left: 5px;
          @include h3-style;
        }
      }
      .room-tile-action {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 7px 10px;
        border-radius: 20px;
        border: 2px solid $CuidadoColor;
        cursor: pointer;
        &.status-libre {
          border: 2px solid $CuidadoColor;
        }
        &.status-mantenimiento {
          border: 2px solid $CigarroColor;
        }
        &.status-ocupado {
          border: 2px solid $GalletaColor;
        }
        .type-content {
          @include h3-style;
          &.status-libre {
            color: $CuidadoColor;
          }
          &.status-mantenimiento {
            color: $CigarroColor;
          }
          &.status-ocupado {
            color: $GalletaColor;
          }
        }
        svg {
          margin-left: 7px;
          width: 20px;
          height: 20px;
          &.status-libre {
            color: $CuidadoColor;
          }
          &.status-mantenimiento {
            color: $CigarroColor;
          }
          &.status-ocupado {
            color: $GalletaColor;
          }
        }
      }
    }
  }
  .room-rent-lefttoolbar {
    display: flex;
    position: absolute;
    left: -15px;
    width: 365px;
    height: 100%;
    margin-left: -365px;
    transition: margin 0.5s;
    border-left: 1px solid $SideElementBottomBorder;
    &.active {
      margin-left: 0px;
      transition: margin 0.5s;
    }
    .body-sidebar {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      height: 100%;
      background: $SidebarTabBackground;
      color: $SidebarColor;
      @include scroll-y;
      .sidebar-element {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 20px 0 5px;
        margin: 0 25px;
        border-bottom: 1px solid $SideElementBottomBorder;
        &:last-child {
          border-bottom: unset;
        }
        .title-sidebar-element {
          text-align: left;
          margin-bottom: 15px;
          @include h3-style;
        }
        .sidebar-sub-element {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          flex-wrap: wrap;
          @include table-style;
          .sub-selector {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            min-height: 40px;
            min-width: 150px;
            margin-bottom: 15px;
            border: 2px solid $SideElementBottomBorder;
            background: $SideSubElementBackground;
            border-radius: 5px;
            cursor: pointer;
            &.active {
              border: 2px solid $CuidadoColor;
              transition: border 0.5s;
            }
            .sub-selector-check {
              text-align: center;
              border: 10px solid #ffff;
              border-radius: 50px;
              width: 20px;
              height: 20px;
              margin-left: 10px;
              transition: border 0.5s;
              &.active {
                border: 10px solid #00cd71;
                transition: border 0.5s;
              }
            }
            .sub-selector-label {
              width: calc(100% - 30px);
              text-align: center;
            }
          }
          .select-option-subcomponent {
            margin-bottom: 15px;
          }
          .select-box-horizontal {
            height: unset !important;
          }
        }
      }
      .inferior-sidebar {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin: 0 25px 15px 25px;
      }
    }
    .body-tab {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      position: absolute;
      right: -80px;
      height: 70px;
      width: 80px;
      border-radius: 0 10px 10px 0;
      background: $SidebarTabBackground;
      color: $SidebarColor;
      cursor: pointer;
      @include h1-style;
      p {
        margin-bottom: unset;
      }
      svg {
        width: 25px;
        height: 25px;
      }
    }
  }
}
