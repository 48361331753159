@import "../../components/variables.scss";

.settings-rol-container{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: calc(100% - 20px);
  width: 100%;
  margin-bottom: 20px;
  background: white;
  border-radius: 10px;
  .settings-rol {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 220px;
    height: 100%;
    padding: 20px 0;
    .rol-list {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: calc(100% - 40px);
      .rol-item{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 180px;
        height: 50px;
        border-radius: 10px;
        margin-bottom: 20px;
        background: white;
        border: 2px solid $SidebarBackground;
        cursor: pointer;
        p {
          @include h3-style;
          color: $SidebarBackground;
        }
        &.active {
          background: $SidebarBackground;
          p {
            color: white;
            
          }
        }
      }
    }
  }
  .settings-permission {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    height: 100%;
    width: calc(100% - 200px);
    padding: 20px 0 0 20px;
    border-left: 1px solid $LightGrayColor;
    @include scroll-x;
    .permission-item {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-content: flex-start;
      // align-items: center;
      width: 350px;
      height: fit-content;
      margin-bottom: 20px;
      margin-right: 20px;
      border: 1px solid $CigarroColor;
      border-radius: 10px;
      .main-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 40px;
        padding: 0 20px;
        @include h3-style;
      }
      .description {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        height: 40px;
        border-top: 1px solid $CigarroColor;
        background: $ElementBackgroundColor;
        @include h11-style;
      }
      .permission {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        height: 40px;
        border-top: 1px solid $CigarroColor;
        @include h10-style
      }
      .permission,.description{
        .lower {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          width: 30%;
          height: 100%;
        }
        .bigger {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          width: 70%;
          height: 100%;
        }
      }
    }
  }
}