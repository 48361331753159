@import "../../components/variables.scss";

.products-sell-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  .products-sell-list {
    display: flex;
    flex-direction: column;
    width: calc(100% - 340px);
    .products-sell-toolbar{
      .toolbar-left {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        height: 100%;
        .search-box-subcomponent {
          margin-right: 20px;
        }
        .select-option-subcomponent {
          margin-right: 20px;
        };
      };                      
    };
    .product-list{
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin-top: 20px;
      margin-right: 5px;
      @include scroll-y;
      .product-element{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        position: relative;
        width: 190px;
        height: 190px;
        margin: 0px 20px 20px 0px;
        border: 4px solid #ced4da;
        border-radius: 10px;
        background: white;
        &.element-selected {
          border: 4px solid #8f9538bf;
        }
        cursor: pointer;
        .name{
          height: 51px;
          width: 140px;
          @include h5-style;
        };
        .side-color{
          position: absolute ;
          top: -4px;
          right: -4px;
          height: 50px;
          width: 15px;
          border-bottom-left-radius: 10px;
          border-top-right-radius: 10px;
          .flag {
            height: 100%;
            width: 100%;
            border-bottom-left-radius: 10px;
            border-top-right-radius: 10px;
          }
          .side-color-bebida {
            border: 1px solid $BebidaColor;
            background: $BebidaColor;
          }
          .side-color-snack {
            border: 1px solid $SnackColor;
            background: $SnackColor;
          }
          .side-color-galleta {
            border: 1px solid $GalletaColor;
            background: $GalletaColor;
          }
          .side-color-chocolate {
            border: 1px solid $ChocolateColor;
            background: $ChocolateColor;
          }
          .side-color-cuidado {
            border: 1px solid $CuidadoColor;
            background: $CuidadoColor;
          }
          .side-color-caramelo {
            border: 1px solid $CarameloColor;
            background: $CarameloColor;
          }
          .side-color-cigarro {
            border: 1px solid $CigarroColor;
            background: $CigarroColor;
          }
        };
        .price-list{
          @include h7-style;  
        };
        .stock{
          @include h5-style;
        }
        svg{
          position: absolute;
          margin: auto;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          width: 50%;
          height: 50%;
          opacity: 0.1;
        };
      };
    };
  };
  .products-right{
    display: flex;
    flex-direction: column;
    width: 340px;
    align-items: center;
    height: calc(100% - 20px);
    border: 2px solid #ced4da;
    border-radius: 15px;
    .header-right{
      display: flex;  
      flex-direction: column;
      justify-content: space-around;
      align-self: center;
      min-height: 90px;
      height: 90px;
      width: 100%;
      border-radius: 5px;
      .title{
        display: flex;
        align-self: center;
        @include h2-style;
        color: #000;
      };
      .details{
        display: flex;
        flex-direction: column;
        justify-content: center;
        @include h8-style;
        color: #575757;
      };
    };
    .body-right{
      display: flex;
      flex-direction: column;
      position: relative;
      align-items: center;
      width: 100%;
      height: calc(100% - 225px);
      border-top: 2px solid #ced4da;
      border-bottom: 2px solid #ced4da;
      @include scroll-y;
      .sell-elements{
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        padding: 0 20px;
        width: 300px;
        min-height: 115px;
        margin-top: 20px;
        border-radius: 8px;
        border: 3px solid #ced4da;
        background: #ffffff;
        &:last-of-type {
          margin-bottom: 20px;
        }
        .name-price{
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          .name{
            @include h9-style
          }
        }
        .body-sell-elements{
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          .quantity{
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: baseline;
            .quantity-text{
              @include h9-style
            };
            .quantity-number{ 
              @include light-style
            };
          };     
          .quantity-action{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .add{
              display: flex;
              width: 24px;
              height: 24px;
              border: 1px solid #000
            };
            .remove{
              display: flex;
              margin-left: 10px;
              width: 24px;
              height: 24px;
              border: 1px solid #000
            };
            .add,.remove{
              svg{
                font-size: 24px;
                margin-top: -1px;
              };
            };        
          };   
        };
        .side-right-color{
          display: flex;
          flex-direction: row;
          position: absolute;
          height: 50px;
          width: 10px;
          border: 1px solid #ced4da;
          background-color: #ced4da;
          border-top-right-radius: 25px;
          border-bottom-right-radius: 25px;
          top: 32px;
          left: 0;
        };
      };
    };
    .footer-right{
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 135px;
      padding: 15px;
      .sale{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 50px;
        width: 100%;
        padding: 0 15px;
        border: 3px solid #ced4da;
        background: #ffffff;
        border-radius: 8px;
        margin-bottom: 15px;
        @include h5-style;
      };
      .sell-cancel{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      };
    };
  };
};
