@import "../../components/variables.scss";

.login-container {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  min-height: 100vh;
  .login-image {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 50%;
    min-height: 100vh;
    padding: 30px 30px 80px 30px;
    background-repeat: no-repeat;
    background-size: cover;
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      content: "";
      width: 50%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.4);
    }
    .login-logo {
      height: 90px;
      margin-bottom: 110px;
      z-index: 1;
      img {
        height: 90px;
      }
    }
    .login-message {
      z-index: 1;
      .row-2 {
        text-decoration: underline;
        text-decoration-thickness: 2px;
        text-align: right;
        font-weight: 600;
        font-size: 40px;
        line-height: 50px;
        color: #FFFFFF;
        font-family: Verdana, Geneva, Tahoma, sans-serif;
      }
    }
  }
  .login-box {
    height: 100%;
    width: 50%;
    min-height: 100vh;
    padding-left: 65px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .login-form {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 330px;
      margin: 90px 0;
      .main-title {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding-bottom: 25px;
        p {
          font-weight: 400;
          font-size: 25px;
          line-height: 29px;
          color: #0D0B30;
        }
        .profit-text {
          margin-left: 7px !important;
          font-weight: 600;
          background: linear-gradient(174deg, #0095fc 0%, #73c3fc 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
        }
      }
      .login-button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 40px;
        width: 110px;
        background: linear-gradient(315.68deg, #0095fc 0%, #73c3fc 100%) !important;
        border: none !important;
        border-radius: 10px;
        cursor: pointer;
        // span {
        //   display: none;
        // }
        span {
          font-weight: 600;
          font-size: 18px;
          line-height: 21px;
          color: #FFFFFF;
          // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        }
      }
      .input-number-title,.input-box-title {
        font-size: 15px !important;
        font-weight: 600;
      }
    }
    .login-copy {
      text-align: left;
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
    }
  }
}