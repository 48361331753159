@import "../../components/variables.scss";

.rooms-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  .room-toolbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 40px;
    margin-bottom: 20px;
    .toolbar-left {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      height: 100%;
      .filter-option-subcomponent {
        margin-right: 20px;
      }
      .search-box-subcomponent {
        margin-right: 20px;
      }
      .select-option-subcomponent {
        margin-right: 20px;
      }
    }
    .toolbar-right {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      height: 100%;
    }
  }
  .room-list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    height: calc(100% - 75px);
    margin-bottom: 15px;
    .numero-field{
      width: 105px !important;
      min-width: 105px !important;
      max-width: 105px !important;
    }
    .piso-field{
      width: 50px !important;
      min-width: 50px !important;
      max-width: 50px !important;
    }
    .precio-field{
      width: calc(calc(100% - 645px) * 0.5) !important;
      min-width: calc(calc(100% - 645px) * 0.5) !important;
      max-width: calc(calc(100% - 645px) * 0.5) !important;
    }
    .tipo-field{
      width: calc(calc(100% - 645px) * 0.5) !important;
      min-width: calc(calc(100% - 645px) * 0.5) !important;
      max-width: calc(calc(100% - 645px) * 0.5) !important;
    }
    .status-field{
      width: 195px !important;
      min-width: 195px !important;
      max-width: 195px !important;
    }
    .descripcion-field{
      width: 95px !important;
      min-width: 95px !important;
      max-width: 95px !important;
    }
    .liberado-field{
      width: 120px !important;
      min-width: 120px !important;
      max-width: 120px !important;
    }
    .acciones-field{
      width: 80px !important;
      min-width: 80px !important;
      max-width: 80px !important;
    }
  }
}
.modal-rooms-add,
.modal-rooms-delete,
.modal-rooms-edit,
.modal-rooms-enable { 
  .modal-content {
    border-radius: 1rem;
    .modal-header {
      border-bottom: unset;
      padding: 0.75rem 0.75rem 0 0.75rem;
      .title {
        color: $TopHeaderLabelColor;
        @include h2-style;
      }
      svg {
        width: 30px;
        height: 30px;
      }
    }
    .modal-body {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
      .add-room-input,
      .edit-room-input {
        width: calc(50% - 10px);
        span {
          width: 100%;
        }
      }
      .add-room-select,
      .edit-room-select {
        width: calc(50% - 10px);
        .select-option-subcomponent {
          border: 1px solid #ced4da;
          width: 100%;
        }
      }
    }
    .modal-footer {
      border-top: unset;
      padding: 0 0.75rem 0.75rem 0.75rem;
    }
  }
}