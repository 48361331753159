@import "./variables.scss";

.layout {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  max-height: 100vh;
  min-height: 100vh;
  .body-layout {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: calc(100% - 195px);
    overflow: hidden;
    // height: 100%;
    .body {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 100%;
      height: calc(100% - 65px);
      padding: 20px 30px 0 15px;
      background: $BodyBackgroundColor;
    }
  }
}

//Hidden-wall Style SCSS
.hidden-wall {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 999;
  cursor: default;
}

//Menu Style SCSS
.menu-left-side {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: $BackgroundColor;
  // height: 100%;
  width: 195px;
  // padding-left: 25px;
  // padding-right: 25px;
  .menu-top-side {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 0 0 0;
    .top-img {
      width: 100px;
      height: 100px;
    }
    .top-title {
      text-align: center;
      width: 200px;
      padding-bottom: 10px;
      color: $MenuOptionLabelActiveColor;
      border-bottom: 1px solid $MenuTitleBorderColor;
      @include table-style;
    }
  }
  .menu-bottom-side {
    @include scroll-y;
    .nav-item-profile {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: $MenuOptionHeight;
      width: 100%;
      min-height: 60px;
      max-height: 60px;
      height: 60px;
      text-decoration: none;
      padding: 0 15px;
      z-index: 2;
      .content-item-profile {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        height: 100%;
        svg {
          color: $MenuOptionLabelColor;
          width: $IconMenuHeightWidth;
          height: $IconMenuHeightWidth;
          padding-right: 10px;
        }
        .title-menu {
          color: $MenuOptionLabelColor;
          margin: 0px;
          @include normal-style;
        }
      }
    }
    .nav-item-profile.active {
      .border-active {
        margin-right: 0;
        color: $MenuOptionLabelActiveColor;
        height: 20px;
        border: 2px solid $MenuOptionLabelActiveColor;
        border-radius: 5px;
      }
      svg {
        color: $MenuOptionLabelActiveColor;
      }
      .title-menu {
        color: $MenuOptionLabelActiveColor;
      }
    }
    .menu-item {
      cursor: pointer;
      .menu-item-icon {
        color: $MenuOptionLabelColor;
        width: $IconMenuHeightWidth;
        height: $IconMenuHeightWidth;
        margin-right: -10px;
        transform: rotate(0deg);
        transition: transform 0.5s;
      }
    }
    .menu-item.active {
      .menu-item-icon {
        transform: rotate(180deg);
        transition: transform 0.5s;
      }
    }
    .sub-menu-list {
      padding-left: 50px;
      border-top: 1px solid $SubListBorderCorlor;
      border-bottom: 1px solid $SubListBorderCorlor;
      background: $SubListBackgroundColor;
      margin-left: -25px;
      margin-right: -25px;

      // height: 200px;
      // transition: height 0.5s ease-in-out;
      .sub-menu-item {
        min-height: 50px;
        max-height: 50px;
        height: 50px;
        .border-active {
          margin-right: 25px;
        }
      }
    }
    .sub-menu-list.hidden {
      display: none;
    }
  }
}
.menu-left-side.hidden {
}

//Top Style SCSS
.top-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 65px;
  background: $WhiteColor;
  .top-left-side {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-left: 15px;
    svg {
      color: $MenuOptionLabelColor;
      width: $IconTopHeightWidth;
      height: $IconTopHeightWidth;
      margin-right: 15px;
      cursor: pointer;
    }
    .title {
      color: $TopHeaderLabelColor;
      @include h2-style;
    }
  }
  .top-rigth-side {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-right: 30px;
    .product-resume,
    .room-resume,
    .profile {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      background: $ElementBackgroundColor;
      cursor: pointer;
      margin-left: 20px;
      svg {
        color: $ElementColor;
        width: $IconTopRightHeightWidth;
        height: $IconTopRightHeightWidth;
      }
    }
  }
}

//InputDate Style SCSS
.input-date-horizontal,
.input-date-vertical {
  display: flex;
  justify-content: flex-start;
  .input-date-title {
    @include table-style;
  }
  input {
    height: 40px;
    // width: 200px;
    border-radius: 7px;
    outline: none;
    color: $SearchLabelColor;
    @include normal-style;
  }
  .input-date-error {
    @include light-style;
    color: $GalletaColor;
  }
  .error-mode {
    &:hover {
      border-color: $GalletaColor !important;
    }
    border: 1px solid $GalletaColor !important;
    border-radius: 7px !important;
  }
}
.input-date-horizontal {
  flex-direction: row;
  align-items: center;
  height: 40px;
  .input-date-title {
    margin-right: 15px;
  }
  .input-date-error {
    margin-left: 15px;
  }
}
.input-date-vertical {
  flex-direction: column;
  align-items: flex-start;
  height: 90px;
  .input-date-title {
    padding-bottom: 4px;
  }
  .input-date-error {
    padding-top: 4px;
    align-self: flex-end;
  }
}

//InputField Style SCSS
.input-box-horizontal,
.input-box-vertical {
  display: flex;
  justify-content: flex-start;
  position: relative;
  .input-box-title {
    @include table-style;
  }
  input {
    height: 40px;
    // width: 200px;
    border-radius: 7px;
    outline: none;
    color: $SearchLabelColor;
    @include normal-style;
  }
  .input-box-error {
    @include light-style;
    color: $GalletaColor;
  }
  .error-mode {
    &:hover {
      border-color: $GalletaColor !important;
    }
    border: 1px solid $GalletaColor !important;
    border-radius: 7px !important;
  }
  .currency-mode {
    padding-left: 22px;
  }
  .currency {
    position: absolute;
    bottom: 34px;
    left: 8px;
  }
}
.input-box-horizontal {
  flex-direction: row;
  align-items: center;
  height: 40px;
  .input-box-title {
    margin-right: 15px;
  }
  .input-box-error {
    margin-left: 15px;
  }
}
.input-box-vertical {
  flex-direction: column;
  align-items: flex-start;
  height: 90px;
  .input-box-title {
    padding-bottom: 4px;
  }
  .input-box-error {
    padding-top: 4px;
    align-self: flex-end;
  }
}

//NumberInput Style SCSS
.input-number-horizontal,
.input-number-vertical {
  display: flex;
  justify-content: flex-start;
  position: relative;
  .input-number-title {
    @include table-style;
  }
  input {
    height: 40px;
    // width: 200px;
    border-radius: 7px;
    outline: none;
    color: $SearchLabelColor;
    @include normal-style;
  }
  .input-number-error {
    @include light-style;
    color: $GalletaColor;
  }
  .error-mode {
    &:hover {
      border-color: $GalletaColor !important;
    }
    border: 1px solid $GalletaColor !important;
    border-radius: 7px !important;
  }
}
.input-number-horizontal {
  flex-direction: row;
  align-items: center;
  height: 40px;
  .input-number-title {
    margin-right: 15px;
  }
  .input-number-error {
    margin-left: 15px;
  }
}
.input-number-vertical {
  flex-direction: column;
  align-items: flex-start;
  height: 90px;
  .input-number-title {
    padding-bottom: 4px;
  }
  .input-number-error {
    padding-top: 4px;
    align-self: flex-end;
  }
}

//SearchBox Style SCSS
.search-box-subcomponent {
  display: flex;
  position: relative;
  height: 40px;
  width: 200px;
  .search-box {
    height: 40px;
    width: 200px;
    padding: 0 15px;
    border: none;
    border-radius: 7px;
    outline: none;
    color: $SearchLabelColor;
    @include normal-style;
  }
  svg {
    position: absolute;
    right: 10px;
    top: 7px;
    color: $MenuOptionLabelColor;
    width: $IconSearchBoxHeightWidth;
    height: $IconSearchBoxHeightWidth;
  }
}

//FilterOption Style SCSS
.filter-option-subcomponent {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 40px;
  .filter-option {
    display: flex;
    align-items: center;
    color: $FilterLabelColor;
    background: $WhiteColor;
    border-right: 2px solid $FilterBorderColor;
    height: 40px;
    padding: 0 15px;
    cursor: pointer;
    @include normal-style;
    &:first-child {
      border-radius: 7px 0 0 7px;
    }
    &:last-child {
      border-radius: 0 7px 7px 0;
      border-right: unset;
    }
  }
  .filter-option.active {
    color: $FilterActiveLabelColor;
    background: $FilterActiveBackground;
  }
}

//ProductsOverlay Style SCSS
.products-overlaypanel {
  .p-overlaypanel-content {
    padding: 0 !important;
    .product-title {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      padding: 7px 12px;
      color: #ff4a57;
      @include normal-style;
    }
    .product-list {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      max-height: 190px;
      @include scroll-y;
      .product-row {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding: 12px;
        border-top: 1px solid #98a1b3;
        cursor: pointer;
        height: 47.5px;
        min-height: 47.5;
        @include h11-style;
        svg {
          width: 20px;
          height: 20px;
          margin-right: 10px;
          stroke: #ff9221;
        }
        &:hover {
          background: #f4f7fc;
        }
      }
    }
  }
}

//RoomsOverlay Style SCSS
.rooms-overlaypanel {
  .p-overlaypanel-content {
    padding: 0 !important;
    .room-row {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      padding: 12px;
      border-top: 1px solid #98a1b3;
      cursor: pointer;
      .room-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        @include h11-style;
      }
      .cell-progress {
        margin-top: 10px;
        width: 250px;
        height: 10px;
        background-color: #cfcfcfbf !important;
      }
      &:first-of-type {
        border-top: unset;
      }
      &:hover {
        background: #f4f7fc;
      }
    }
  }
}

//UserOverlay Style SCSS
.user-overlaypanel {
  .p-overlaypanel-content {
    padding: 0 !important;
    .user-title {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 20px;
      min-width: 250px;
      .user-img {
        width: 50px;
        height: 50px;
        margin-right: 10px;
      }
      .user-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .top {
          @include h5-style;
        }
        .bottom {
          @include normal-style;
        }
      }
    }
    .user-options {
      border-top: 1px solid #98a1b3;
      border-bottom: 1px solid #98a1b3;
      .option-row {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding: 15px;
        @include h11-style;
        cursor: pointer;
        &:hover {
          background: #f4f7fc;
          color: #5887ff;
        }
      }
    }
    .user-logout {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 12px;
      @include h11-style;
      cursor: pointer;
      &:hover {
        background: #f4f7fc;
        color: #5887ff;
      }
    }
    svg {
      width: 20px;
      height: 20px;
      margin-right: 15px;
    }
  }
}

//ActionOverlay Style SCSS
.action-overlaypanel {
  .p-overlaypanel-content {
    padding: 0 !important;
    .action-row {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 12px;
      border-top: 1px solid #98a1b3;
      cursor: pointer;
      svg {
        color: #98a1b3;
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
      p {
        @include h11-style;
      }
      &:first-of-type {
        border-top: unset;
      }
      &:hover {
        background: #f4f7fc;
      }
    }
  }
}

//ActionButton Style SCSS
.action-button-subcomponent,
.search-button-subcomponent {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 40px;
}
.action-button-subcomponent,
.search-button-subcomponent,
.modal-footer {
  .button {
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0 15px;
    margin-left: 20px;
    border-radius: 7px;
    color: $ButtonLabelColor;
    cursor: pointer;
    @include normal-style;
    &:first-child {
      margin-left: unset;
    }
    p {
      line-height: normal;
    }
  }
  .button-add,
  .button-modal-add {
    background: $ButtonAddColor !important;
    &:hover {
      background: $ButtonAddColor;
    }
  }
  .button-pdf {
    background: $ButtonPdfColor !important;
    &:hover {
      background: $ButtonPdfColor;
    }
  }
  .button-sell {
    background: $ButtonSellColor !important;
    &:hover {
      background: $ButtonSellColor;
    }
  }
  .button-search,
  .button-modal-edit {
    background: $ButtonSearchColor !important;
    &:hover {
      background: $ButtonSearchColor;
    }
  }
  .button-clean,
  .button-modal-delete {
    background: $ButtonDeleteColor !important;
    &:hover {
      background: $ButtonDeleteColor;
    }
  }
  .button-cancel,
  .button-modal-cancel {
    background: $ButtonCancelColor !important;
    &:hover {
      background: $ButtonCancelColor;
    }
  }
  .button-reduce,
  .button-modal-reduce {
    background: $ButtonReduceColor !important;
    &:hover {
      background: $ButtonReduceColor;
    }
  }
  .button-close,
  .button-modal-close {
    background: $ButtonCloseColor !important;
    &:hover {
      background: $ButtonCloseColor;
    }
  }
  .button-top,
  .button-modal-enable {
    background: $DateModifyBackgroundColor !important;
    &:hover {
      background: $DateModifyBackgroundColor;
    }
  }
}

//SelectOption Style SCSS
.select-box-horizontal,
.select-box-vertical {
  display: flex;
  justify-content: flex-start;
  .select-box-title {
    @include table-style;
  }
  .select-option-subcomponent {
    display: flex;
    position: relative;
    height: 40px;
    width: 200px;
    background: $SelectorBackgroundColor;
    border-radius: 7px;
    cursor: pointer;
    .selected-option {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      color: $SelectorLabelColor;
      padding: 0 15px;
      @include normal-style;
    }
    svg {
      position: absolute;
      right: 10px;
      top: 7px;
      color: $MenuOptionLabelColor;
      width: $IconSearchBoxHeightWidth;
      height: $IconSearchBoxHeightWidth;
    }
    .option-list {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      position: absolute;
      width: 200px;
      border-radius: 7px;
      top: 45px;
      background: $SelectorOptionBackgroundColor;
      color: $SelectorLabelColor;
      border: 2px solid $SelectorBorderColor;
      z-index: 99999;
      @include normal-style;
      .option-element {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        padding: 10px 15px;
        border-bottom: 2px solid $SelectorBorderColor;
        &:last-child {
          border-bottom: unset;
        }
      }
      .option-element.active {
        color: $SelectorActiveLabelColor;
      }
    }
  }
  .select-box-error {
    @include light-style;
    color: $GalletaColor;
  }
  .error-mode {
    &:hover {
      border-color: $GalletaColor !important;
    }
    border: 1px solid $GalletaColor !important;
    border-radius: 7px !important;
  }
}
.select-box-horizontal {
  flex-direction: row;
  align-items: center;
  height: 40px;
  .select-box-title {
    margin-right: 15px;
  }
  .select-box-error {
    margin-left: 15px;
  }
}
.select-box-vertical {
  flex-direction: column;
  align-items: flex-start;
  height: 90px;
  .select-box-title {
    padding-bottom: 4px;
  }
  .select-box-error {
    padding-top: 4px;
    align-self: flex-end;
  }
}

//RadioButton Style SCSS
.radio-option {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 150px;
  height: 40px;
  border-radius: 7px;
  background: #ffff;
  cursor: pointer;
  &.active {
    .radio-circle {
      border: 2px solid #00ad5f;
      background: #00cd71;
      transition: border 0.5s;
      transition: background 0.5s;
    }
  }
  .radio-circle {
    text-align: center;
    border: 2px solid $LightGrayColor;
    background: $BodyBackgroundColor;
    border-radius: 50px;
    width: 20px;
    height: 20px;
    margin-left: 15px;
    transition: border 0.5s;
    transition: background 0.5s;
  }
  .radio-title {
    margin-left: 15px;
    @include table-style;
    font-size: 16px;
  }
}

//Multipanel Style SCSS
.multipanel-title {
  width: 100%;
  padding-bottom: 4px;
  @include table-style;
}
.multipanel-option {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 40px;
  .single-option {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: calc(33% - 10px);
    height: 40px;
    border: 1.5px solid #ced4da;
    transition: border 0.3s;
    background: $WhiteColor;
    border-radius: 5px;
    cursor: pointer;
    @include normal-style;
    &.active {
      border: 1.5px solid $ButtonSearchColor;
      transition: border 0.3s;
    }
    .sub-selector-check {
      text-align: center;
      border: 1.5px solid #ced4da;
      border-radius: 50px;
      width: 20px;
      height: 20px;
      margin-left: 10px;
      transition: border 0.3s;
      &.active {
        border: 10px solid $ButtonSearchColor;
        transition: border 0.3s;
      }
    }
    .sub-selector-label {
      width: calc(100% - 30px);
      text-align: center;
    }
  }
}
.multipanel-option.first {
  margin-bottom: 15px;
}

//Modal Style SCSS
.modal-content {
  .close-button {
    cursor: pointer;
  }
}

//Table Style SCSS
.p-datatable-main {
  width: 100%;
  height: 100%;
  .p-datatable-wrapper {
    @include scroll-y;
    height: calc(100% - 65px);
  }
  .p-paginator {
    margin-top: 15px !important;
    height: 50px !important;
    background: none;
    padding: unset;
  }
}
.table,
.p-datatable-table {
  thead {
    th,
    td {
      color: $HeadLabelColor;
      @include table-style;
      padding: 10px 10px !important;
    }
  }
  tbody {
    th,
    td {
      color: $BodyLabelColor;
      @include table-style;
      padding: 10px 10px !important;
    }
  }
  tr {
    height: 55px;
  }
  .text-align-left {
    flex-direction: row;
    justify-content: left;
  }
  .text-align-center {
    flex-direction: row;
    justify-content: center;
  }
  .text-align-right {
    flex-direction: row;
    justify-content: right;
  }
  .text-align-left,
  .text-align-center,
  .text-align-right {
    .cell-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: fit-content;
      height: 100%;
    }
  }
  .table-action-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 55px;
    .action-detail {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 35px;
      height: 35px;
      background: $ElementBackgroundColor;
      border-radius: 50%;
      cursor: pointer;
      margin-left: 10px;
      &:first-child {
        margin-left: unset;
      }
      svg {
        color: $ElementColor;
        width: $IconActionHeightWidth;
        height: $IconActionHeightWidth;
      }
    }
  }

  /*Cell Type - Lower Case*/
  .lower-class {
    max-width: 140px !important;
    // width: 100% !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  /*Cell Type - Category*/
  .cell-category {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border: 2px solid $ElementBackgroundColor;
    border-radius: 7px;
    width: fit-content;
    .category-bar {
      margin-left: 10px;
      height: 15px;
      border-radius: 10px;
    }
    .category-name {
      padding: 5px 20px 5px 15px;
    }
    .category-class-bebida {
      border: 4px solid $BebidaColor;
    }
    .category-class-snack {
      border: 4px solid $SnackColor;
    }
    .category-class-galleta {
      border: 4px solid $GalletaColor;
    }
    .category-class-chocolate {
      border: 4px solid $ChocolateColor;
    }
    .category-class-cuidado {
      border: 4px solid $CuidadoColor;
    }
    .category-class-caramelo {
      border: 4px solid $CarameloColor;
    }
    .category-class-cigarro {
      border: 4px solid $CigarroColor;
    }
  }

  /*Cell Type - Stock*/
  .cell-stock {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 7px;
    padding: 6px 5px;
    background: $StockBackgroundColor;
    color: $StockLabelColor;
    width: 55px;
  }

  /*Cell Type - Progress*/
  .cell-progress {
    width: 80px;
    height: 15px;
    border-radius: 7px;
    background-color: #cfcfcfbf !important;
  }

  /*Cell Type - Procentage*/
  .cell-porcentage.success {
    color: #00a35a;
  }
  .cell-porcentage.danger {
    color: #ff796b;
  }

  /*Cell Type - Detail - Detail_Rent*/
  .cell-detail {
    padding: 6px 8px;
    border-radius: 7px;
    background: $StockBackgroundColor;
    cursor: pointer;
    position: relative;
    svg {
      color: $ElementColor;
      width: $IconActionHeightWidth;
      height: $IconActionHeightWidth;
    }
    .detail-square {
      border: 1px solid $DetailBorderColor;
      border-radius: 7px;
      background: $TableBackgroundColor;
      z-index: 2;
      // top: 0;
      // right: -85px;
      padding: 10px 10px 10px 25px;
      position: absolute;
      .option-element {
        text-transform: capitalize;
        display: list-item;
      }
    }
    .detail-rent {
      border: 1px solid $DetailBorderColor;
      border-radius: 7px;
      background: $TableBackgroundColor;
      top: 0;
      right: -95px;
      padding: 10px 10px 10px 10px;
      position: absolute;
      .option-element {
        text-transform: capitalize;
        // display: list-item;
      }
    }
  }

  /*Cell Type - Status*/
  .cell-status {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border: 2px solid $ElementBackgroundColor;
    border-radius: 7px;
    width: fit-content;
    .status-bar {
      margin-left: 10px;
      height: 15px;
      border-radius: 10px;
    }
    .status-name {
      padding: 5px 20px 5px 15px;
    }
    .status-class-open {
      border: 4px solid $CuidadoColor;
    }
    .status-class-occupied {
      border: 4px solid $GalletaColor;
    }
    .status-class-maintenance {
      border: 4px solid $BebidaColor;
    }
  }

  /*Cell Type - User_Type*/
  .cell-user-type {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 12px;
    border-radius: 7px;
    background: $StockBackgroundColor;
    color: $StockLabelColor;
  }
  .user-class-administrator {
    background: $AdminBackgroundColor;
    color: $AdminColor;
  }
  .user-class-cleaner {
    background: $LimpiezaBackgroundColor;
    color: $LimpiezaColor;
  }
  .user-class-recepcionist {
    background: $RecepcionistaBackgroundColor;
    color: $RecepcionistaColor;
  }

  /*Cell Type - Date_Create*/
  .cell-date-create {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px 5px;
    border-radius: 7px;
    background: $DateCreateBackgroundColor;
    color: $WhiteColor;
  }

  /*Cell Type - Date_Modify*/
  .cell-date-modify {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px 5px;
    border-radius: 7px;
    background: $DateModifyBackgroundColor;
    color: $WhiteColor;
  }

  /*Cell Type - Ban_Reason*/
  .cell-ban-reason {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: $BanReasonLabelColor;
  }

  /*Cell Type - Ban_Reason*/
  .cell-date-rent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100px;
    .in-date,
    .in-time,
    .out-date,
    .out-time,
    .out-real-date,
    .out-real-time {
      color: $WhiteColor;
      line-height: 5px;
    }
    .in-date,
    .in-time {
      text-align: center;
      background: $DateInRentBackgroundColor;
    }
    .out-date,
    .out-time {
      text-align: center;
      background: $DateOutBackgroundColor;
    }
    .out-real-date,
    .out-real-time {
      text-align: center;
      background: $DateOutRealBackgroundColor;
    }
    .in-date,
    .out-date,
    .out-real-date {
      border-top-left-radius: 7px;
      border-top-right-radius: 7px;
      line-height: 11px;
      padding-top: 5px;
    }
    .in-time,
    .out-time,
    .out-real-time {
      border-bottom-left-radius: 7px;
      border-bottom-right-radius: 7px;
      font-size: 12px !important;
      line-height: 9px;
      padding: 5px 0;
    }
  }
}

.loader {
  height: 100%;
  width: 100%;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .loader-image {
    height: 250px;
    margin-bottom: 30px;
  }
  .progress-container {
    width: 330px;
    height: 35px;
    padding: 5px;
    border-radius: 90px;
    border: 3px solid #007df0;
  }
  .loader-progress {
    width: 100%;
    height: 100%;
    background: #ffffff;
    border-radius: 90px;
    .p-progressbar-value {
      background: #007df0;
    }
  }
}

// .custom-calendar {
//   .p-datepicker {
//     left: -408px !important;
//   }
// }
