@import "../src/components/variables.scss";

body {
  min-height: 100vh;
  width: 100%;
  position: relative;
  display: flex;
}

#root {
  min-height: 100vh;
  width: 100%;
  position: relative;
}

.App {
  height: 100vh;
  width: 100%;
  overflow: hidden;
  text-align: center;
}

input:focus {
  border: 1px solid #ced4da !important;
  box-shadow: none !important;
}

.p-inputtext:focus {
  border: 1px solid #ced4da !important;
  box-shadow: none !important;
}

.p-inputtext:enabled:hover {
  border-color: #ced4da !important;
}

.p-datepicker {
  z-index: 1056 !important;
}

.p-button {
  border: unset !important;
}

.p-button:focus {
  box-shadow: unset !important;
}

.p-toast {
  width: auto !important;
}

.p-inputgroup-addon {
  padding: 0 10px !important;
  min-width: unset !important;
  @include table-style;
}

p{
  margin-bottom: 0 !important;
};

iframe {
  pointer-events: none;
}