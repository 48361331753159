@import "../../components/variables.scss";

.rooms-form-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  // height: 100%;
  width: 100%;
  @include scroll-y;
  .information-box {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background: white;
    border-radius: 10px;
    margin-bottom: 20px;
    .title-section {
      @include h5-style;
      padding: 20px 0;
    }
    .inputs-section {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
      border-top: 1px solid #d5d5d5;
      width: 100%;
      padding: 20px;
      .button-search-dni{
        height: 40px;
        margin-top: 25px;
        margin-left: 15px;
        background: $ButtonSearchColor !important;
        &:hover {
          background: $ButtonSearchColor;  
        }
      } 
    }
  }
  .footer-form {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 20px;
  }
}