@import "../../components/variables.scss";

.rooms-history-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  .room-history-toolbar {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    height: 95px;
    margin-bottom: 20px;
    .history-tiles-list {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      height: 100%;
      width: calc(100% - 210px);
      .room-history-time-tile {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        height: 95px;
        width: 240px;
        padding: 5px 15px;
        border-radius: 7px;
        border: 3px solid #ccc8c8;
        background: #ffff;
        cursor: pointer;
        .tile-first {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          @include h3-style;
          .first-title {
            font-weight: 800;
            &.active {
              color: #6366f1;
            }
          }
          .custom-tile-action {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            .pdf-button {
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              height: 24px;
              width: 24px;
              margin-right: 15px;
              border-radius: 7px;
              background: #ff9221;
              cursor: pointer;
              svg {
                fill: #ffff;
              }
            }
          }
          input {
            display: none;
          }
          button {
            height: 24px;
            width: 24px;
            border-radius: 7px;
            cursor: pointer;
          }
          // svg {
          //   position: absolute;
          //   right: 10px;
          //   padding: 5px;
          //   color: #98a1b3;
          //   width: 30px;
          //   height: 30px;
          //   z-index: 99999;
          //   cursor: pointer;
          //   background: #eff0f6;
          //   border-radius: 7px;
          // }
        }
        .tile-amount {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          line-height: 30px;
          @include h4-style;
        }
        .tile-last {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: center;
          @include table-style;
          .last-legend {
            font-weight: 700;
            padding-right: 10px;
            color: #5d6b83;
          }
          .last-qty {
            font-weight: 800;
            font-size: 15px;
          }
        }
      }
      .room-history-time-tile.active {
        border: 3px solid #5887ff;
      }
    }
    .history-filters-list {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      height: 100%;
      width: 180px;
      margin-right: 30px;
      border-right: 2px solid #ccc8c8;
      .history-title {
        text-align: left;
        margin-bottom: 0;
        @include h3-style;
      }
    }
  }
  .room-history-list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    height: calc(100% - 130px);
    margin-bottom: 15px;
    .row-finalized {
      background: #ffd2d2a8;
    }
    .nombre-field {
      width: calc(calc(100% - 850px) * 1) !important;
      min-width: calc(calc(100% - 850px) * 1) !important;
      max-width: calc(calc(100% - 850px) * 1) !important;
    }
    .habitacion-field,
    .descripcion-field,
    .turnos-field {
      width: 70px !important;
      min-width: 70px !important;
      max-width: 70px !important;
    }
    .total-field {
      width: 100px !important;
      min-width: 100px !important;
      max-width: 100px !important;
    }
    .ingreso-field,
    .salida-field,
    .salida-real-field {
      width: 120px !important;
      min-width: 120px !important;
      max-width: 120px !important;
    }
    .penalidad-field {
      width: 100px !important;
      min-width: 100px !important;
      max-width: 100px !important;
    }
    .acciones-field {
      width: 80px !important;
      min-width: 80px !important;
      max-width: 80px !important;
    }
    .cell-date-rent {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100px;
      .in-date,
      .in-time,
      .out-date,
      .out-time,
      .out-real-date,
      .out-real-time {
        color: $WhiteColor;
        line-height: 5px;
      }
      .in-date,
      .in-time {
        text-align: center;
        background: $DateInRentBackgroundColor;
      }
      .out-date,
      .out-time {
        text-align: center;
        background: $DateOutBackgroundColor;
      }
      .out-real-date,
      .out-real-time {
        text-align: center;
        background: $DateOutRealBackgroundColor;
      }
      .in-date,
      .out-date,
      .out-real-date {
        border-top-left-radius: 7px;
        border-top-right-radius: 7px;
        line-height: 11px;
        padding-top: 5px;
      }
      .in-time,
      .out-time,
      .out-real-time {
        border-bottom-left-radius: 7px;
        border-bottom-right-radius: 7px;
        font-size: 12px !important;
        line-height: 9px;
        padding: 5px 0;
      }
    }
  }
}

.modal-rooms-delete,
.modal-rent-detail,
.modal-rent-preferent,
.modal-rent-banned {
  .modal-content {
    border-radius: 1rem;
    .modal-header {
      border-bottom: unset;
      padding: 0.75rem 0.75rem 0 0.75rem;
      .title {
        color: $TopHeaderLabelColor;
        @include h2-style;
      }
      svg {
        width: 30px;
        height: 30px;
      }
    }
    .modal-body {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
    }
    .modal-footer {
      border-top: unset;
      padding: 0 0.75rem 0.75rem 0.75rem;
    }
  }
}

.modal-rent-addturn,
.modal-rent-addpenalty {
  .modal-content {
    border-radius: 1rem;
    .modal-header {
      border-bottom: unset;
      padding: 0.75rem 0.75rem 0 0.75rem;
      .title {
        color: $TopHeaderLabelColor;
        @include h2-style;
      }
      svg {
        width: 30px;
        height: 30px;
      }
    }
    .modal-body {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
      height: 173px;
      gap: 19px;
      .add-turn-title {
        @include table-style;
      }
      .add-penalty-input,
      .add-turn-input {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
      }
      .modal-footer {
        border-top: unset;
        padding: 0 0.75rem 0.75rem 0.75rem;
      }
    }
    .modal-footer {
      border-top: unset;
      padding: 0 0.75rem 0.75rem 0.75rem;
    }
  }
}
.title {
  @include table-style;
  font-weight: 700;
}
.info {
  @include table-style;
}
