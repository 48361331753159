@import "../../components/variables.scss";

.products-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  .product-toolbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 40px;
    margin-bottom: 20px;
    .toolbar-left {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      height: 100%;
      .filter-option-subcomponent {
        margin-right: 20px;
      }
      .search-box-subcomponent {
        margin-right: 20px;
      }
      .select-option-subcomponent {
        margin-right: 20px;
      }
    }
    .toolbar-right {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      height: 100%;
    }
  }
  .product-list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    height: calc(100% - 75px);
    margin-bottom: 15px;
    .nombre-field {
      width: calc(calc(100% - 640px) * 0.7) !important;
      min-width: calc(calc(100% - 640px) * 0.7) !important;
      max-width: calc(calc(100% - 640px) * 0.7) !important;
    }
    .categoria-field {
      width: 200px !important;
      min-width: 200px !important;
      max-width: 200px !important;
    }
    .unitario-field {
      width: calc(calc(100% - 640px) * 0.3) !important;
      min-width: calc(calc(100% - 640px) * 0.3) !important;
      max-width: calc(calc(100% - 640px) * 0.3) !important;
    }
    .stock-field {
      width: 120px !important;
      min-width: 120px !important;
      max-width: 120px !important;
    }
    .minimo-field {
      width: 120px !important;
      min-width: 120px !important;
      max-width: 120px !important;
    }
    .porcentaje-field {
      width: 120px !important;
      min-width: 120px !important;
      max-width: 120px !important;
    }
    .acciones-field {
      width: 80px !important;
      min-width: 80px !important;
      max-width: 80px !important;
    }
  }
}

.modal-products-add,
.modal-products-delete,
.modal-products-edit,
.modal-products-detail {
  .modal-content {
    border-radius: 1rem;
    .modal-header {
      border-bottom: unset;
      padding: 0.75rem 0.75rem 0 0.75rem;
      .title {
        color: $TopHeaderLabelColor;
        @include h2-style;
      }
      svg {
        width: 30px;
        height: 30px;
      }
    }
    .modal-body {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
      .p-datatable-wrapper {
        height: 100% !important;
      }
      .add-product-input,
      .edit-product-input {
        width: calc(50% - 10px);
        span {
          width: 100%;
        }
      }
      .add-product-select,
      .edit-product-select {
        width: calc(50% - 10px);
        .select-option-subcomponent {
          border: 1px solid #ced4da;
          width: 100%;
        }
      }
    }
    .modal-footer {
      border-top: unset;
      padding: 0 0.75rem 0.75rem 0.75rem;
    }
  }
  .usuario-field {
    width: calc(calc(100% - 480px) * 1) !important;
    min-width: calc(calc(100% - 480px) * 1) !important;
    max-width: calc(calc(100% - 480px) * 1) !important;
  }
  .stock-field {
    width: 120px !important;
    min-width: 120px !important;
    max-width: 120px !important;
  }
  .previo-field {
    width: 120px !important;
    min-width: 120px !important;
    max-width: 120px !important;
  }
  .actual-field {
    width: 120px !important;
    min-width: 120px !important;
    max-width: 120px !important;
  }
  .creacion-field {
    width: 120px !important;
    min-width: 120px !important;
    max-width: 120px !important;
  }
}

.modal-products-addstock,
.modal-products-reduce {
  .modal-content {
    border-radius: 1rem;
    .modal-header {
      border-bottom: unset;
      padding: 0.75rem 0.75rem 0 0.75rem;
      .title {
        color: $TopHeaderLabelColor;
        @include h2-style;
      }
      svg {
        width: 30px;
        height: 30px;
      }
    }
    .modal-body {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
      .input-number-title {
        height: 100%;
        margin-top: 5px;
      }
      .add-stockproduct-input,
      .reduce-stockproduct-input {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
      }
      .modal-footer {
        border-top: unset;
        padding: 0 0.75rem 0.75rem 0.75rem;
      }
      .add-product-select {
        width: calc(50% - 10px);
        .select-option-subcomponent {
          border: 1px solid #ced4da;
          width: 100%;
        }
      }
    }
    .modal-footer {
      border-top: unset;
      padding: 0 0.75rem 0.75rem 0.75rem;
    }
  }
}
