@import "../../components/variables.scss";

.rooms-search-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  .room-search-toolbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 40px;
    margin-bottom: 20px;
    .toolbar-left {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      height: 100%;
      .filter-option-subcomponent {
        margin-right: 20px;
      }
      .search-box-subcomponent {
        margin-right: 20px;
      }
      .select-option-subcomponent {
        margin-right: 20px;
      }
    }
    .toolbar-right {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      height: 100%;
    }
  }
  .room-search-list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    height: calc(100% - 75px);
    margin-bottom: 15px;
    .total-field{
      width: calc(calc(100% - 480px) * 0.7) !important;
      min-width: calc(calc(100% - 480px) * 0.7) !important;
      max-width: calc(calc(100% - 480px) * 0.7) !important;
    }
    .habitacion-field{
      width: 120px !important;
      min-width: 120px !important;
      max-width: 120px !important;
    }
    .turnos-field{
      width: 120px !important;
      min-width: 120px !important;
      max-width: 120px !important;
    }
    .total-field{
      width: calc(calc(100% - 480px) * 0.3) !important;
      min-width: calc(calc(100% - 480px) * 0.3) !important;
      max-width: calc(calc(100% - 480px) * 0.3) !important;
    }
    .ingreso-field,.salida-real-field{
      width: 120px !important;
      min-width: 120px !important;
      max-width: 120px !important;
    }
  }
}
