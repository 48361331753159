@import "../../components/variables.scss";

.settings-general-container{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: calc(100% - 20px);
  width: 100%;
  margin-bottom: 20px;
  background: white;
  border-radius: 10px;
  .settings-body{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
    height: calc(100% - 80px);
    padding: 25px 25px 0px 25px;
    @include scroll-y;
    .setting-element {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 300px;
      height: 92px;
      margin-bottom: 25px;
      .title {
        @include table-style;
        padding-bottom: 10px;
      }
      .setting-input{
        height: 40px;
        color: $SearchLabelColor;
        @include normal-style;
        input {
          color: $SearchLabelColor;
          @include normal-style;
        }
      }
      .footer {
        @include light-style;
      }
    }
  }
  .settings-footer{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 80px;
    padding: 0px 25px;
    border-top: 1px solid $LightGrayColor;
  }
}